import {useLocale} from "@components/Contexts/LocaleContext"
import {translate} from "@helpers/translate"
import {useRouter} from "next/router"
import {FC} from "react"

interface TranslateProps {
	children: string | undefined
	capitalize?: boolean
	capitalizeFirst?: boolean
}

export const Translate: FC<TranslateProps> = ({
	children,
	capitalize = false,
	capitalizeFirst = false,
}) => {
	const router = useRouter()
	const {locale} = router
	const {locale: customeLocale} = useLocale()

	const localeCustom = customeLocale || locale

	const translatedText = children
		? translate(children, localeCustom || "en", capitalizeFirst, capitalize)
		: children

	return <>{translatedText}</>
}
