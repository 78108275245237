import LoginSelect from "@components/Theme/LoginSelect/LoginSelect"
import ExternalIcon from "@components/icons/ExternalIcon"
import {Theme} from "@customTypes/customTypes"
import {
	MenuModuleType,
	SubMenuModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import {useRouter} from "next/router"
import React, {useEffect, useState} from "react"
import {ReactSVG} from "react-svg"
import {CSSTransition, TransitionGroup} from "react-transition-group"

import ButtonComponent from "../../Button/Button"
import LanguageSwitcher from "../../LanguageSwitcher/LanguageSwitcher"
import Link from "../../Link/Link"
import SubNavigationMobile from "../SubNavigation/SubNavigationMobile"

import styles from "./HeaderMobile.module.scss"

const HeaderMobile = (props: {
	data: MenuModuleType[]
	subMenu: SubMenuModuleType | undefined
	showHeader: boolean
	theme: Theme
}) => {
	const router = useRouter()

	const [isOpen, setIsOpen] = useState(false)
	const [isOpenSublist, setIsOpenSublist] = useState<number[]>([0])
	const [showSub, setShowSub] = useState<boolean>(props.showHeader)

	const toggleMenu = () => {
		if (!isOpen) {
			setShowSub(false)
		} else {
			setShowSub(true)
		}
		setIsOpen(!isOpen)
	}
	const toggleMenuSublist = (index: number) => {
		setIsOpenSublist((prevState) =>
			prevState.includes(index)
				? prevState.filter((expandedId) => expandedId !== index)
				: [...prevState, index],
		)
	}

	const logoStyle = {
		height: "60px",
		width: "80px",
		marginLeft: "5%",
	}

	const renderLogo = (style: any) => (
		<div style={style}>
			<Link link={"/"} onClick={isOpen ? toggleMenu : () => {}}>
				<img alt={"Logo"} src={"/DB_logo_RGB.svg"} style={style} />
			</Link>
		</div>
	)

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflowY = "hidden"
		} else {
			document.body.style.overflowY = "auto"
		}

		// clean up effect
		return () => {
			document.body.style.overflowY = "auto"
		}
	}, [isOpen])

	return (
		<>
			<>
				<TransitionGroup>
					{isOpen ? (
						<CSSTransition
							classNames={{
								enter: styles.menuEnter,
								enterActive: styles.menuEnterActive,
								exit: styles.menuExit,
								exitActive: styles.menuExitActive,
							}}
							timeout={500}
						>
							<nav
								className={`${styles.navbarMenu} ${styles.open}`}
							>
								<div
									className={styles.closeButton}
									onClick={toggleMenu}
								/>

								<ul className={styles.list}>
									{props.data.map((element, index) => {
										const checkExpanded =
											typeof element.children?.length !==
												"undefined" &&
											element.children.length > 0 &&
											!element.parent
										const checkLink =
											typeof element.children?.length !==
												"undefined" &&
											element.children.length === 0 &&
											!element.parent

										const active =
											element.link &&
											router.asPath.replace(
												/\/|#[^/]*$/g,
												"",
											) ===
												element.link.replace(
													/\/|#[^/]*$/g,
													"",
												)
												? styles.active
												: ""

										const external =
											element.link?.includes("http")
										if (checkExpanded || checkLink) {
											return (
												<li
													key={index}
													className={styles.listItem}
												>
													{checkLink && (
														<ButtonComponent
															buttonType={
																"unstyled"
															}
															className={`${
																styles.button
															} ${active} ${
																external
																	? styles.external
																	: ""
															}`}
															link={element.link}
															size={"default"}
															asLink
															onClick={toggleMenu}
														>
															<span
																className={`${styles.linkWithImageContainer}`}
															>
																{element
																	.menuLinkIconSvg
																	?.src && (
																	<ReactSVG
																		className={`${element.brand}`}
																		src={
																			element
																				.menuLinkIconSvg
																				?.src
																		}
																		style={{
																			height: "30px",
																		}}
																		wrapper={
																			"span"
																		}
																	/>
																)}
																{
																	element.menuTitle
																}
															</span>
															{external && (
																<ExternalIcon
																	className={`${
																		styles.svgIcon
																	} ${
																		styles[
																			element
																				.menuLinkIconSvg
																				?.src
																				? "withIcon"
																				: ""
																		]
																	} `}
																/>
															)}
														</ButtonComponent>
													)}
													{checkExpanded && (
														<ButtonComponent
															buttonType={
																"unstyled"
															}
															className={
																String(
																	styles.button,
																) +
																" " +
																String(
																	styles.expanded,
																) +
																" " +
																String(
																	isOpenSublist.includes(
																		index,
																	)
																		? styles.active
																		: "",
																)
															}
															size={"default"}
															onClick={() =>
																toggleMenuSublist(
																	index,
																)
															}
														>
															{element.menuTitle}
														</ButtonComponent>
													)}
													<TransitionGroup>
														{isOpenSublist.includes(
															index,
														) && (
															<CSSTransition
																classNames={{
																	enter: styles.listEnter,
																	enterActive:
																		styles.listEnterActive,
																	exit: styles.listExit,
																	exitActive:
																		styles.listExitActive,
																}}
																timeout={500}
															>
																<>
																	{/* <ul>
																		{element.children?.map(
																			(
																				element2: MenuModuleType,
																				index2: number,
																			) => ( */}
																	<ul
																		className={
																			styles.LinksList
																		}
																	>
																		{element.children?.map(
																			(
																				element3: MenuModuleType,
																				index3: number,
																			) => {
																				const external =
																					element3.link?.includes(
																						"http",
																					)

																				const active =
																					element3.link &&
																					router.asPath.replace(
																						/\/|#[^/]*$/g,
																						"",
																					) ===
																						element3.link.replace(
																							/\/|#[^/]*$/g,
																							"",
																						)
																						? styles.active
																						: ""
																				return (
																					<li
																						key={
																							index3
																						}
																					>
																						<ButtonComponent
																							buttonType={
																								"unstyled"
																							}
																							className={`${
																								styles.buttonLink
																							} ${active} ${
																								external
																									? styles.external
																									: ""
																							}`}
																							link={
																								element3.link
																							}
																							size={
																								"default"
																							}
																							asLink
																							withoutTarget
																							onClick={
																								toggleMenu
																							}
																						>
																							{
																								element3.menuTitle
																							}
																							{external && (
																								<ExternalIcon
																									className={
																										styles.svgIcon
																									}
																								/>
																							)}
																						</ButtonComponent>
																					</li>
																				)
																			},
																		)}
																	</ul>
																	{/* ),
																		)}
																	</ul> */}
																</>
															</CSSTransition>
														)}
													</TransitionGroup>
												</li>
											)
										}
										return null
									})}
								</ul>
							</nav>
						</CSSTransition>
					) : null}
				</TransitionGroup>
			</>
			<header
				className={`${styles.container} ${
					props.showHeader ? styles.show : styles.hide
				}`}
			>
				<div className={styles.header}>
					{renderLogo(logoStyle)}
					<div className={styles.header__nav}>
						<div>
							<div
								className={
									String(styles.burgerButton) +
									(isOpen ? " " + String(styles.active) : "")
								}
								onClick={toggleMenu}
							>
								<div className={styles.line} />
								<div className={styles.line} />
								<div className={styles.line} />
							</div>
						</div>
						<div className={styles.spacer} />
						<div className={styles.headerButtons}>
							<LanguageSwitcher />
							<LoginSelect />
						</div>
					</div>
				</div>
				<div className={`${styles.subHeader} ${props.theme}`}>
					{showSub && props.subMenu && (
						<CSSTransition
							classNames={{
								enter: styles.menuEnter,
								enterActive: styles.menuEnterActive,
								exit: styles.menuExit,
								exitActive: styles.menuExitActive,
							}}
							timeout={300}
						>
							<SubNavigationMobile
								data={props.subMenu}
								header={props.showHeader}
								theme={props.theme}
							/>
						</CSSTransition>
					)}
				</div>
			</header>
		</>
	)
}

export default HeaderMobile
