import {ReactNode, createContext, useContext, useState} from "react"

interface LocaleContextProps {
	locale: string
	changeLocale: (newLocale: string) => void
}

export const LocaleContext = createContext<LocaleContextProps | undefined>(
	undefined,
)

export const LocaleProvider: React.FC<{
	children: ReactNode
	customLocale?: string
}> = ({children, customLocale = "en"}) => {
	const [locale, setLocale] = useState<string>(customLocale)

	const changeLocale = (newLocale: string) => {
		setLocale(newLocale)
	}

	return (
		<LocaleContext.Provider value={{locale, changeLocale}}>
			{children}
		</LocaleContext.Provider>
	)
}

export const useLocale = (): LocaleContextProps => {
	const context = useContext(LocaleContext)
	if (!context) {
		throw new Error("useLocale must be used within a LocaleProvider")
	}
	return context
}
