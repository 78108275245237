import React from "react"

type Props = {
	className: string
}

const SignInIcon = (props: Props) => {
	return (
		<svg
			aria-hidden={"true"}
			className={props.className}
			data-testid={"LoginIcon"}
			focusable={"false"}
			viewBox={"0 0 24 24"}
		>
			<path
				d={
					"M11 7 9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8z"
				}
			/>
		</svg>
	)
}

export default SignInIcon
