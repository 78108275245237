import SignInIcon from "@components/icons/SignInIcon"
import Link from "next/link"
import {useEffect, useRef, useState} from "react"
import {CSSTransition} from "react-transition-group"

import ButtonComponent from "../Button/Button"

import styles from "./LoginSelect.module.scss"

type Props = {}

const LoginSelect = (props: Props) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const menuRef = useRef<HTMLDivElement>(null)

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}

	/**
	 * Hook that alerts clicks outside of the passed ref
	 */
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: any) {
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setIsMenuOpen(false)
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [menuRef])

	return (
		<div ref={menuRef} className={styles.LoginSelect}>
			<ButtonComponent
				className={`${styles.selectedOption} ${
					!isMenuOpen ? "" : styles.active
				}`}
				onClick={toggleMenu}
			>
				Sign in
			</ButtonComponent>
			<CSSTransition
				classNames={{
					enter: styles.enter,
					enterActive: styles.enterActive,
					exit: styles.exit,
					exitActive: styles.exitActive,
				}}
				in={isMenuOpen}
				timeout={200}
				unmountOnExit
			>
				<div className={`${styles.selectMenu}`}>
					<div key={"studio"} className={`${styles.option}`}>
						{/* <ButtonComponent
							className={styles.loginButton}
							link={"https://dreambroker.com/studio/login"}
							style={{
								width: "100%",
								borderRadius: 0,
							}}
							asLink
						> */}
						<Link
							className={`${styles.optionBox} ${styles.studio}`}
							href={"https://dreambroker.com/studio/login"}
							target={"__blank"}
						>
							<div>Studio </div>
							<div>
								<SignInIcon className={styles.svgIcon} />
							</div>
						</Link>

						{/* </ButtonComponent> */}
					</div>
					<div key={"one"} className={`${styles.option}`}>
						{/* <ButtonComponent
							brand={"one"}
							className={styles.loginButton}
							link={"https://app.one.dreambroker.com/"}
							style={{
								width: "100%",
								borderRadius: 0,
							}}
							asLink
						> */}
						<Link
							className={`${styles.optionBox} ${styles.one}`}
							href={"https://app.one.dreambroker.com/"}
							target={"__blank"}
						>
							<div>#One </div>
							<div>
								<SignInIcon className={styles.svgIcon} />
							</div>
						</Link>

						{/* </ButtonComponent> */}
					</div>
				</div>
			</CSSTransition>
		</div>
	)
}

export default LoginSelect
