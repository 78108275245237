import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import {Theme} from "@customTypes/customTypes"
import {
	SubMenuLinksType,
	SubMenuModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import Link from "../../Link/Link"
import SubNavLink from "../HeaderComponents/SubNavLink"

import styles from "./SubNavigation.module.scss"

type Props = {
	data: SubMenuModuleType
	header: boolean
	theme: Theme
}

const SubNavigation = (props: Props) => {
	const buttons = props.data.links.filter((element) => element.asButton)

	return (
		<div className={`${styles.subHeader} ${props.theme}`}>
			<ContainerWrapper>
				<div className={styles.headerContainer}>
					<div className={styles.navBox}>
						{props.data.subLogo && (
							<div className={styles.logoWrapper}>
								<Link
									className={`${styles.logoBox} ${styles.second}`}
									link={props.data.links?.[0].link}
								>
									<NewResponsiveImage
										withoutContainer
										{...props.data.subLogo}
										imageClassName={styles.logo}
									/>
								</Link>
							</div>
						)}
						<nav className={styles.navWrapper}>
							<ul className={styles.navList}>
								{props.data.links.map(
									(
										navBar: SubMenuLinksType,
										navBarIndex: number,
									) => {
										if (!navBar.asButton) {
											const stylesLink = `${styles.link} `
											return (
												<SubNavLink
													key={navBarIndex}
													data={navBar}
													stylesLink={stylesLink}
												/>
											)
										}
										return (
											<React.Fragment key={navBarIndex} />
										)
									},
								)}
							</ul>
						</nav>
					</div>
					{buttons && (
						<ul
							className={styles.navList}
							style={{
								gap: "16px",
							}}
						>
							{buttons.map((element, index) => {
								return (
									<SubNavLink
										key={props.data.links.length + index}
										data={element}
										stylesLink={`${styles.button} ${
											element.asPrimary ||
											(!props.header &&
												buttons.length <= 1)
												? styles.primary
												: styles.secondary
										}`}
									/>
								)
							})}
						</ul>
					)}
				</div>
			</ContainerWrapper>
		</div>
	)
}

export default SubNavigation
