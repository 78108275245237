import ContainerWrapper from "@components/BasicComponents/Wrappers/ContainerWrapper/ContainerWrapper"
import {HeaderContext} from "@components/Contexts/HeaderContext"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import {getPosts} from "@config/modules"
import {MenuModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import debounce from "@hooks/debounce"
import MenuDataJson from "@jsons/headerNavigation.json"
import React, {useContext, useEffect, useState} from "react"

import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"
import Link from "../Link/Link"
import LoginSelect from "../LoginSelect/LoginSelect"

import HeaderMobile from "./HeaderMobile/HeaderMobile"
import Navigation from "./Navigation/Navigation"
import SecondaryNavigation from "./Navigation/SecondaryNavigation"
import styles from "./NewHeader.module.scss"
import SubNavigation from "./SubNavigation/SubNavigation"
type Props = {}

const NewHeader = (props: Props) => {
	const {subMenuData, theme} = useContext(HeaderContext)
	const menu = getPosts("menuModule", {
		data: MenuDataJson,
	})

	const [showHeader, setShowHeader] = useState(true)
	const [prevScrollPos, setPrevScrollPos] = useState(0)

	useEffect(() => {
		const handleScroll = debounce(() => {
			const currentScrollPos = window.pageYOffset
			const scrollDifference = prevScrollPos - currentScrollPos
			const visible = Math.abs(scrollDifference) >= 150

			if (visible && currentScrollPos > 300) {
				setShowHeader(currentScrollPos < prevScrollPos)
				setPrevScrollPos(currentScrollPos)
			}

			if (currentScrollPos <= 300) {
				setShowHeader(true)
				setPrevScrollPos(currentScrollPos)
			}
		}, 100)

		window.addEventListener("scroll", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
			clearTimeout(handleScroll as unknown as NodeJS.Timeout) // Clear any pending debounce
		}
	}, [prevScrollPos])

	const mainMenuWrapper = menu.find((element) => element.slug === "main-menu")
	const secondaryMenuWrapper = menu.find(
		(element) => element.slug === "secondary-menu",
	)

	const mainMenu: MenuModuleType[] = menu
		.filter((element) => element.parent?.id === mainMenuWrapper?.id)
		.map((element) => {
			element.parent = null
			return element
		})
	const secondaryMenu: MenuModuleType[] = menu
		.filter((element) => element.parent?.id === secondaryMenuWrapper?.id)
		.map((element) => {
			element.parent = null
			return element
		})

	return (
		<>
			<header
				className={`${styles.header} ${
					showHeader ? styles.show : styles.hide
				} `}
			>
				<div>
					<div className={styles.secondaryHeaderContainer}>
						<ContainerWrapper
							style={{
								width: "100%",
								position: "relative",
							}}
						>
							<SecondaryNavigation menu={secondaryMenu} />
						</ContainerWrapper>
					</div>

					<ContainerWrapper>
						<div className={styles.headerContainer}>
							<div className={styles.navBox}>
								<div className={styles.logoWrapper}>
									<Link
										className={styles.logoBox}
										link={"/"}
										withoutTarget
									>
										<NewResponsiveImage
											alt={"Logo"}
											height={80}
											imageClassName={styles.logo}
											src={"/DB_logo_RGB.svg"}
											width={106}
											withoutContainer
										/>
									</Link>
								</div>
								<Navigation menu={mainMenu} />
							</div>

							<div className={styles.headerButtons}>
								<LanguageSwitcher />
								<LoginSelect />
							</div>
						</div>
					</ContainerWrapper>
				</div>
				<div className={`${styles.subHeader} ${theme}`}>
					{subMenuData && (
						<SubNavigation
							data={subMenuData}
							header={showHeader}
							theme={theme}
						/>
					)}
				</div>
			</header>

			<HeaderMobile
				data={[...mainMenu, ...secondaryMenu]}
				showHeader={showHeader}
				subMenu={subMenuData}
				theme={theme}
			/>
		</>
	)
}

export default NewHeader
