import da from "@locales/da.json"
import de from "@locales/de.json"
import en from "@locales/en.json"
import fi from "@locales/fi.json"
import is from "@locales/is.json"
import nl from "@locales/nl.json"
import no from "@locales/no.json"
import se from "@locales/se.json"
import {useRouter} from "next/router"

import {capitalizeFirstSymbol} from "./capitalizeFirstSymbol"

interface Translations {
	[key: string]: string
}
const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

const translations: {[locale: string]: Translations} = {
	en,
	fi,
	se,
	da,
	no,
	de,
	nl,
	is,
}

export function translate(
	key: string,
	locale: string,
	capitalizeFirst?: boolean,
	capitalizeAll?: boolean,
): string {
	const selectedTranslationsR = translations[locale]
	const selectedTranslationsL = translations.en

	const targetKey = key.toLowerCase().replace(/[^a-zA-Z]/g, "")

	function searchTranslation(
		translationsObj: {[key: string]: string},
		targetKey: string,
	): string | false {
		for (const translationKey in translationsObj) {
			if (
				translationKey.toLowerCase().replace(/[^a-zA-Z]/g, "") ===
				targetKey
			) {
				return translationsObj[translationKey]
			}
		}
		return false
	}

	const searchTranslationItem = searchTranslation(
		selectedTranslationsR,
		targetKey,
	)
	const searchTranslationDefault = searchTranslation(
		selectedTranslationsL,
		targetKey,
	)
	const realItem = searchTranslationItem || searchTranslationDefault || key

	if (locale !== "en") {
		if (searchTranslationItem) {
			if (capitalizeFirst) {
				return capitalizeFirstLetter(realItem)
			}
			return searchTranslationItem
		} else if (searchTranslationDefault && capitalizeAll) {
			return capitalizeFirstSymbol(searchTranslationDefault)
		}

		return realItem
	}

	if (capitalizeAll) {
		return capitalizeFirstSymbol(realItem)
	}
	if (capitalizeFirst) {
		return capitalizeFirstLetter(realItem)
	}

	return realItem
}

export const translateWithRouter = (
	data: string,
	capitalizeFirst?: boolean,
) => {
	const router = useRouter()
	const {locale} = router

	return translate(data, locale || "en", capitalizeFirst)
}
