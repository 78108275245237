import "../styles/reset.scss"
import "../styles/globals.scss"

import CookieProvider from "@components/Contexts/CookieContext"
import {HeaderProvider} from "@components/Contexts/HeaderContext"
import LayoutProvider from "@components/Contexts/LayoutContext"
import {LocaleProvider} from "@components/Contexts/LocaleContext"
import {MetaDataProvide} from "@components/Contexts/MetaDataContext "
import Layout from "@components/Theme/Layout/Layout"
import type {AppProps} from "next/app"
import React from "react"

export default function App({
	Component,
	pageProps,
	router,
}: AppProps): JSX.Element {
	return (
		<>
			<LocaleProvider>
				<MetaDataProvide>
					<CookieProvider>
						<HeaderProvider>
							<LayoutProvider>
								<div className={"App"} />
								{/* <HeadMeta /> */}
								<Layout
									Component={Component}
									pageProps={pageProps}
									router={router}
								/>
							</LayoutProvider>
						</HeaderProvider>
					</CookieProvider>
				</MetaDataProvide>
			</LocaleProvider>
		</>
	)
}
